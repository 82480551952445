<template>
  <div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <label
      class="mr-1 h4"
      style="color:black"
    >Nombre de colis: {{ orders.length }}
    </label>
    <b-col>
      <b-form-group
        label="Référence de colis"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="ref"
            placeholder="Référence de colis"
            @change="checkOrder"
          />
        </b-input-group>
      </b-form-group>
      <!-- table -->
      <div class="d-flex justify-content-between">
        <b-card>
          <h4>colis au depot: {{ ordersInRepository.length }}</h4>
          <vue-good-table
            :columns="columns"
            :rows="ordersInRepository"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm ,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="CheckSquareIcon"
                    class="cursor-pointer mr-1"
                    size="16"
                    color="green"
                    @click="addItem(props.row)"
                  />
                  <b-tooltip
                    title="Ajouter"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <b-card class="ml-1">
          <h4>colis dans feuille de route: {{ orders.length }}</h4>
          <vue-good-table
            :columns="columns"
            :rows="orders"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="DeleteIcon"
                    color="red"
                    class="cursor-pointer mr-1"
                    size="16"
                    @click="resetItem(props.row)"
                  />
                  <b-tooltip
                    title="Annuler"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>

          </vue-good-table>
          <b-form-group
            label="Livreur"
          >
            <validation-provider
              #default="{ errors }"
              name="Livreur"
            >
              <v-select
                v-model="roadmap.delivery_man"
                :clearable="false"
                placeholder="Livreur"
                label="username"
                :options="livreurs"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Voiture"
          >
            <validation-provider
              #default="{ errors }"
              name="Voiture"
            >
              <v-select
                v-model="roadmap.delivery_man.car"
                :clearable="false"
                placeholder="Voiture"
                label="serial_number"
                :options="cars"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group class="d-flex justify-content-end">
            <b-button
              variant="gradient-primary"
              class="ml-1 d-inline-block"
              :class="{ 'disabled-cursor': isLoading }"
              :disabled="isLoading"
              @click="updateRoadMap"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Enregistrer</span>
            </b-button>
          </b-form-group>
        </b-card>
      </div>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorVoice from '@/assets/audio/error.mp3'
import ok from '@/assets/audio/ok.mp3'

export default {
  name: 'EditRoadmap',
  components: {
    BButton,
    ValidationProvider,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BTooltip,
    vSelect,
    BCard,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      livreurs: [],
      livreur: '',
      date: '01-10-2021',
      status: '',
      roadmap: {},
      pageLength: 10,
      dir: false,
      errorVoice,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Bl',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Bl',

          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher délégation',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'Tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
      ],
      role: '',
      orders: [],
      ordersInRepository: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      cars: [],
      car: {},
      repository: {},
      repositories: [],
      isLoading: false,
      ok,
    }
  },

  created() {
    this.user = storeAuth.state.user
    this.getRoadmap()
    this.getCars()
  },
  methods: {
    async getCars() {
      this.isLoading = true
      const { data } = await axios.get('/api/cars/')
      this.cars = data
      this.isLoading = false
    },
    checkOrder() {
      const result = this.ordersInRepository.find(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
        }
        return element.id === Number(this.ref)
      })
      if (result) {
        this.addItem(result).then(() => { this.ref = '' })
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(errorVoice)
      }
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    async updateRoadMap() {
      this.isLoading = true
      this.orders_deleted = this.ordersInRepository.filter(item => item.status === 3)

      const obj = {
        orders: this.orders.map(item => item.id),
        user: this.user,
        orders_deleted: this.orders_deleted.map(item => item.id),
        delivery_man: this.roadmap.delivery_man.id,
        car: this.roadmap.delivery_man.car.serial_number,
        id: this.roadmap.id,
      }
      await axios.post('/api/roadmaps/update-roadmap/', obj).then(response => {
        this.$router.push(`/roadmaps/show/${response.data}`)
        this.showToast('success', 'top-center', 'Feuille de route modifié avec succés')
        this.isLoading = false
      })
        .catch(error => {
          this.isLoading = false
          this.showToast('danger', 'top-center', error.toString())
        })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    async addItem(item) {
      this.orders.push(item)
      const i = this.ordersInRepository.findIndex(element => element.id === item.id)
      this.ordersInRepository.splice(i, 1)
    },
    async resetItem(item) {
      this.ordersInRepository.push(item)
      const i = this.orders.findIndex(element => element.id === item.id)
      this.orders.splice(i, 1)
    },
    async getRoadmap() {
      this.isLoading = true
      const response = await axios
        .get(`/api/roadmaps/detail/${this.$route.params.id}`)
      this.roadmap = response.data
      this.orders = this.roadmap.orders
      const response2 = await axios.get(`/api/orders/get-status-deposit/${this.roadmap.repository.id}`)
      this.ordersInRepository = response2.data
      const response3 = await axios.get('api/auth/users/drivers/', {
        params: {
          repository: this.roadmap.repository.id,
        },
      })
      this.livreurs = response3.data
      this.isLoading = false
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
