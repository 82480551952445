var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading === true)?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('label',{staticClass:"mr-1 h4",staticStyle:{"color":"black"}},[_vm._v("Nombre de colis: "+_vm._s(_vm.orders.length)+" ")]),_c('b-col',[_c('b-form-group',{attrs:{"label":"Référence de colis","label-for":"vi-first-name"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MaximizeIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":"Référence de colis"},on:{"change":_vm.checkOrder},model:{value:(_vm.ref),callback:function ($$v) {_vm.ref=$$v},expression:"ref"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-card',[_c('h4',[_vm._v("colis au depot: "+_vm._s(_vm.ordersInRepository.length))]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.ordersInRepository,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm ,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-show-icon"),"icon":"CheckSquareIcon","size":"16","color":"green"},on:{"click":function($event){return _vm.addItem(props.row)}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Ajouter","target":("invoice-row-" + (props.row.id) + "-show-icon")}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" de "+_vm._s(props.total)+" résultats trouvés ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1),_c('b-card',{staticClass:"ml-1"},[_c('h4',[_vm._v("colis dans feuille de route: "+_vm._s(_vm.orders.length))]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.orders,"search-options":{
            enabled: true,
            externalQuery: _vm.searchTerm }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-show-icon"),"icon":"DeleteIcon","color":"red","size":"16"},on:{"click":function($event){return _vm.resetItem(props.row)}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Annuler","target":("invoice-row-" + (props.row.id) + "-show-icon")}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" de "+_vm._s(props.total)+" résultats trouvés ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])}),_c('b-form-group',{attrs:{"label":"Livreur"}},[_c('validation-provider',{attrs:{"name":"Livreur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Livreur","label":"username","options":_vm.livreurs},model:{value:(_vm.roadmap.delivery_man),callback:function ($$v) {_vm.$set(_vm.roadmap, "delivery_man", $$v)},expression:"roadmap.delivery_man"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Voiture"}},[_c('validation-provider',{attrs:{"name":"Voiture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Voiture","label":"serial_number","options":_vm.cars},model:{value:(_vm.roadmap.delivery_man.car),callback:function ($$v) {_vm.$set(_vm.roadmap.delivery_man, "car", $$v)},expression:"roadmap.delivery_man.car"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-1 d-inline-block",class:{ 'disabled-cursor': _vm.isLoading },attrs:{"variant":"gradient-primary","disabled":_vm.isLoading},on:{"click":_vm.updateRoadMap}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Enregistrer")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }